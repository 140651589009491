import {Link, StaticQuery, graphql} from 'gatsby'
import React, {FC, useContext, useState} from 'react'
import {animated as a, useSpring} from 'react-spring'

import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'
import LogoGatsby from '../../images/svgs/tech/Gatsby_Monogram.svg'
import LogoGraphql from '../../images/svgs/tech/graphql.svg'
import LogoJs from '../../images/svgs/tech/javascript.svg'
import LogoPrismic from '../../images/svgs/tech/prismic.svg'
import LogoReact from '../../images/svgs/tech/React-icon.svg'
import LogoStyled from '../../images/sc.png'
import LogoTailwind from '../../images/svgs/tech/tailwind_monogram.svg'
import LogoTs from '../../images/svgs/tech/typescript.svg'
import {cachedDataVersionTag} from 'v8'
import styled from 'styled-components'
import tag_colors from '../../../data/tags/tag_colors.json'
import useBoop from '../shared/hoverAnimation'

// import LogoSc from '../../images/svgs/tech/styled-components.svg'

interface details {
  title?: string
  image_1: any
  date: string
  image_2?: any
  role: string
  tech?: string
  tags: []
  description: string
  link: string
}

const SpringWrapper = styled(a.div)`
  position: absolute;
  max-width: 500px;
  max-height: 500px;
  width: 16rem;
  height: 16rem;
  cursor: pointer;
  /* background-size: cover; */
  overflow: hidden;
  border-radius: 0.5rem;
  &:hover {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
      var(--tw-shadow);
  }
  will-change: transform, opacity;
`

const ClientCard: FC<details> = ({
  title,
  image_1,
  image_2,
  date,
  role,
  tech,
  tags,
  description,
  link,
}) => {
  const [flipped, set] = useState(false)
  const {transform, opacity} = useSpring({
    opacity: flipped ? 1 : 0,
    transform: `perspective(600px) rotateX(${flipped ? 180 : 0}deg)`,
    config: {mass: 5, tension: 400, friction: 80},
  })

  return (
    <StaticQuery
      query={graphql`
        query {
          allTagsJson {
            edges {
              node {
                gatsby
                react
                javascript
              }
            }
          }
        }
      `}
      render={(data) => (
        <>
          <div
            className="flex flex-col font-sans  relative h-64  w-64 mr-12 z-50"
            onClick={() => set((state) => !state)}>
            <SpringWrapper
              className=""
              style={{opacity: opacity.interpolate((o) => 1 - o), transform}}>
              <BackgroundImage className="hover:shadow-lg w-full h-full rounded" fluid={image_1} />
            </SpringWrapper>
            <SpringWrapper
              style={{
                opacity,
                transform: transform.interpolate((t) => `${t} rotateX(180deg)`),
              }}>
              <div className="w-full flex flex-col h-full bg-black rounded-lg p-6">
                {flipped ? (
                  <a onClick={() => set((state) => !state)} target="_blank" href={link}>
                    <div className="text-white text-base tracking-wide underline ">{title}</div>
                  </a>
                ) : (
                  <div className="text-white text-base tracking-wide underline ">{title}</div>
                )}
                <div className="text-xs text-white mb-2">{description}</div>
                <div className="text-white text-sm font-thin">{role}</div>
                <div className="text-white italic font-mono text-sm">{date}</div>
                <div className="leading-header mt-4 text-white text-xs font-thin underline">
                  technologies
                </div>
                {/* <div className="border-b border-white mb-4"></div> */}
                <div className="flex flex-row flex-wrap  mt-4 ">
                  {tags.map((tag) => {
                    return <div className="mb-2">{renderLogo(tag, flipped, set)}</div>
                  })}
                </div>
              </div>
            </SpringWrapper>
          </div>
          <div className="text-sm   my-8 w-64">
            <a href={link} target="_blank">
              <div className="leading-header font-sans font-medium text-sm tracking-widest my-2 hover:text-white">
                {title}
              </div>
              <div className=" border-b border-white w-full "></div>
            </a>
            <div className="text-xs text-gray mb-4">{description}</div>
            {/* {tags.map((tag) => {
              console.log(data.allTagsJson.edges[0].node[tag])
              return (
                <div
                  className={`text-xs inline-block mr-1 + ${data.allTagsJson.edges[0].node[tag]}`}>
                  {tag}
                </div>
              )
            })} */}
          </div>
        </>
      )}
    />
  )
}

const renderLogo = (tag, flipped, set) => {
  const [style, trigger] = useBoop({y: 2})
  switch (tag) {
    case 'javascript':
      return (
        <a.div
          style={style}
          onMouseEnter={trigger}
          onClick={() => set((state) => !state)}
          className="mr-2">
          {flipped ? (
            <a href="https://en.wikipedia.org/wiki/JavaScript" target="_blank">
              <LogoJs className="h-6 w-auto " />
            </a>
          ) : (
            <LogoJs className="h-6 w-auto " />
          )}
        </a.div>
      )
    case 'typescript':
      return (
        <a.div
          style={style}
          onMouseEnter={trigger}
          onClick={() => set((state) => !state)}
          className="mr-2">
          {flipped ? (
            <a href="https://www.typescriptlang.org/" target="_blank">
              <LogoTs className="h-6 w-auto " />
            </a>
          ) : (
            <LogoTs className="h-6 w-auto " />
          )}
        </a.div>
      )
    case 'gatsby':
      return (
        <a.div
          style={style}
          onMouseEnter={trigger}
          onClick={() => set((state) => !state)}
          className="mr-2 text-white">
          {flipped ? (
            <a href="https://www.gatsbyjs.com" target="_blank">
              <LogoGatsby className="h-6 w-auto" />
            </a>
          ) : (
            <LogoGatsby className="h-6 w-auto " />
          )}
          {/* <div className="inline-block">//</div> */}
        </a.div>
      )
    case 'graphql':
      return (
        <a.div
          style={style}
          onMouseEnter={trigger}
          onClick={() => set((state) => !state)}
          className="mr-2 text-white">
          {flipped ? (
            <a href="https://graphql.org/" target="_blank">
              <LogoGraphql className="h-6 w-auto" />
            </a>
          ) : (
            <LogoGraphql className="h-6 w-auto " />
          )}
          {/* <div className="inline-block">//</div> */}
        </a.div>
      )
    case 'react':
      return (
        <a.div
          style={style}
          onMouseEnter={trigger}
          onClick={() => set((state) => !state)}
          className="mr-2">
          {flipped ? (
            <a href="https://reactjs.org" target="_blank">
              <LogoReact className="h-6 w-auto " />
            </a>
          ) : (
            <LogoReact className="h-6 w-auto " />
          )}
        </a.div>
      )
    case 'tailwind':
      return (
        <a.div
          style={style}
          onMouseEnter={trigger}
          onClick={() => set((state) => !state)}
          className="mr-2">
          {flipped ? (
            <a href="https://tailwindcss.com/" target="_blank">
              <LogoTailwind className="h-5 w-auto " />
            </a>
          ) : (
            <LogoTailwind className="h-5 w-auto " />
          )}
        </a.div>
      )
    case 'styledcomponents':
      return (
        <a.div
          style={style}
          onMouseEnter={trigger}
          onClick={() => set((state) => !state)}
          className="mr-2">
          {flipped ? (
            <a href="https://styled-components.com/" target="_blank">
              <img className="h-6 w-auto" src={LogoStyled} />
              {/* <LogoSc className="h-6 w-auto " /> */}
            </a>
          ) : (
            <img className="h-6 w-auto" src={LogoStyled} />
            // <LogoSc className="h-6 w-auto " />
          )}
        </a.div>
      )
    case 'prismic':
      return (
        <a.div
          style={style}
          onMouseEnter={trigger}
          onClick={() => set((state) => !state)}
          className="mr-2">
          {flipped ? (
            <a href="https://prismic.io" target="_blank">
              <LogoPrismic className="h-6 w-auto " />
            </a>
          ) : (
            <LogoPrismic className="h-6 w-auto " />
          )}
        </a.div>
      )

    default:
      return null
  }
}

export default ClientCard
